import formValidator from "@/mixin/formValidator";

export default {
    inject: ['toast'],
    mixins: [formValidator],
    data: {},
    methods: {
        clearAEH() {
            this.clearValidationErrors();
        },
        handleApiError(apiResponse) {
            this.clearAEH();

            if (apiResponse.forbidden) {
                return this.toast('success', 'Permission denied! Please contact your administrator.');
            }

            if (apiResponse.validation) {
                return this.validationErrors = apiResponse.validation;
            }

            if (apiResponse.exception) {
                return this.toast('success', apiResponse.exception);
            }
        },
        exportRequestObjectErrors(objectName) {
            let response = {};

            Object.keys(this.validationErrors)
                .filter(error => error.includes(objectName))
                .forEach(errorKey => {
                    const key = errorKey.replace(`${objectName}.`, '');

                    response[key] = this.validationErrors[errorKey];
                });

            return response;
        }
    }
}
